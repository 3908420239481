import React from "react";
import styles from "./footer.module.css";
import { TbWorldWww } from "react-icons/tb";
import { AiOutlineLinkedin } from "react-icons/ai";
import { TbBrandYoutube } from "react-icons/tb";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import Header from "./../Header/Header";
import IndiaFlag from "../../../assets/indaiFlag.png";
import UsaFlag from "../../../assets/USAFalg.png";
import { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { Button, Modal } from "antd";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import pdf from "../../../assets/manual.pdf";

const Footer = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const docs = [{ uri: pdf }];
  return (
    <>
      <div className={styles.footerWrapper}>
        <div className={styles.footer_card}>
          <div className={styles.footer_1}>
            <div className={styles.footerLogo}>
              <img src="	https://icretegy.com/wp-content/uploads/2023/05/ICT-LOGO-PNG-WHITE.png" />
            </div>
            {/* <PiStudentBold className={styles.footer_logo} /> */}
            <div>
              <img />
            </div>
            <p>
              iCretegy is a branch of iPharmaSolution LLC which is the global
              Pharma business holders and change makers define the future.
            </p>
            <div className={styles.copy_rights}>
              Copyright @ 2024 iCAMS<sup>®</sup>
            </div>
          </div>
          <div className={styles.footer_2}>
            <Link className={styles.footer_2_link} to="/">
              Home
            </Link>
            <Link className={styles.footer_2_link} to="/signup">
              Sign up
            </Link>
            <Link className={styles.footer_2_link} to="/login">
              Log in
            </Link>
            <Link className={styles.footer_2_link} to='/t_s'>Terms & Conditions</Link>
            <Link className={styles.footer_2_link} to='/privacyAndPolicy' >Privacy Policy</Link>
          </div>
          <div className={styles.footer_3}>
            <h2>Contact Info</h2>
            <div className={styles.footer_contact_info}>
              <p>6 Ribeiro Ct, Holbrook, NY 11741, USA</p>
              <div className={styles.flagWrp}>
                <div className={styles.email}>
                  <MdOutlineMail />
                </div>
                <span> <a className={styles.footer_2_link} href="mailto:inquiry@icretegy.com"  >inquiry@icretegy.com</a></span>
              </div>
              <div className={styles.flagWrp}>
                <div className={styles.email}>
                  <MdOutlineMail />
                </div>
                <span> <a className={styles.footer_2_link} href="mailto:alpesh@iCretegy.com"  >alpesh@iCretegy.com</a></span>
              </div>
              <div className={styles.flagWrp}>
                <div className={styles.flag}>
                  <img src={IndiaFlag} />
                </div>
                <span>+91 9898289121</span>
              </div>
              <div className={styles.flagWrp}>
                <div className={styles.flag}>
                  <img src={UsaFlag} />
                </div>
                <span>+1-201-916-2870</span>
              </div>
            </div>
            <div className={styles.footer_social_media}>
              <a href="https://icretegy.com/" target="_blank">
                <TbWorldWww />
              </a>
              <a href="https://wa.me/+919898289121" target="_blank">
                <BsWhatsapp />
              </a>
              <a
                href="https://www.linkedin.com/company/linkedin-com-icretegysolutions1/"
                target="_blank"
              >
                <AiOutlineLinkedin />
              </a>
              <a
                href="https://www.youtube.com/@AlpeshPatel-fw3fe"
                target="_blank"
              >
                <TbBrandYoutube />
              </a>
            </div>
          </div>
        </div>
     
      </div>
    </>
  );
};

export default Footer;
